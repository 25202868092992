import React, { Fragment, PropsWithChildren } from 'react'
import { ContentShowType } from '@/enums/Content'
import RenderOnly from './RenderOnly'

interface DisplayConfigWrapperProps {
  show: ContentShowType
  className?: string
}

function DisplayConfigWrapper({
  show,
  className,
  children,
}: PropsWithChildren<DisplayConfigWrapperProps>) {
  switch (show) {
    case ContentShowType.BOTH:
      return <Fragment>{children}</Fragment>
    case ContentShowType.MOBILE:
      return (
        <RenderOnly.Mobile className={className}>{children}</RenderOnly.Mobile>
      )
    case ContentShowType.PC:
      return <RenderOnly.Pc className={className}>{children}</RenderOnly.Pc>
  }
  return null
}

export default DisplayConfigWrapper
