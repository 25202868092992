import { trackEvent } from '@/helpers/logger'
import { FONT_18_EXTRA_BOLD, FONT_22_EXTRA_BOLD } from '@/styles/fonts'
import { STYLE_BREAKPOINT, STYLE_COLOR } from '@/styles/variables'
import { ContentTabItem, TabProperty } from '@/types/Content'
import { css, ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Hidden } from '@kakaomobility/tui-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'

export enum TabThemeType {
  BLACK04_BLACK = 'black04_black',
  BLACK04_YELLOW = 'black04_yellow',
}

type TabTheme = {
  [key in TabThemeType]: {
    color: STYLE_COLOR
    currentColor: STYLE_COLOR
  }
}

const Theme: TabTheme = {
  [TabThemeType.BLACK04_BLACK]: {
    color: STYLE_COLOR.BLACK04,
    currentColor: STYLE_COLOR.BLACK01,
  },
  [TabThemeType.BLACK04_YELLOW]: {
    color: STYLE_COLOR.BLACK04,
    currentColor: STYLE_COLOR.YELLOW,
  },
}

interface TabProps {
  list: ContentTabItem[]
  onClick: (index: number) => void
  line?: boolean
  tabSpace?: number
  lineSpace?: number
  className?: string
  tabTheme?: TabThemeType
  padding?: boolean
  activeTabId?: number
  activeTabScroll?: boolean
  hiddenTitle?: TabProperty['tabHiddenTitle']
}

interface TabStyleProps {
  tabSpace?: number
  lineSpace?: number
  isCurrent?: boolean
  line?: boolean
  theme?: TabTheme[TabThemeType]
  padding?: boolean
}

const transition = css`
  span {
    transition: all 0.3s ease-out;
  }

  div {
    opacity: 1;
    transition: all 0.3s ease-out;
    top: -12px;
  }
`

function Tabs({
  list,
  line,
  tabSpace = 40,
  lineSpace = 8,
  tabTheme = TabThemeType.BLACK04_BLACK,
  padding,
  className,
  onClick,
  activeTabId,
  activeTabScroll,
  children,
  hiddenTitle,
}: PropsWithChildren<TabProps>): ReactElement {
  const router = useRouter()

  const [currentTabId, setCurrentTabId] = useState<number | null>(
    activeTabId ?? null
  )

  useEffect(() => {
    setCurrentTabId(activeTabId)
  }, [activeTabId])

  useEffect(() => {
    const initialTabId = router?.query?.initialTabId

    if (initialTabId) {
      setCurrentTabId(Number(initialTabId))
    }
  }, [router?.query])

  const currentTabRefs = useRef([])
  const tabWrapRef = useRef(null)

  const handleClick = (index): void => {
    setCurrentTabId(index)

    const currentItem = list.find((item) => item.id === index)
    const { logPageName, logEventName } = currentItem

    if (logEventName) {
      trackEvent({ pageName: logPageName, actionName: logEventName })
    }

    return onClick(index)
  }

  useEffect(() => {
    const tab = currentTabRefs?.current[currentTabId ?? 0]
    const wrap = tabWrapRef?.current

    if (!activeTabScroll || !tab || !wrap) {
      return
    }

    const tabWidth =
      tabSpace +
      (tab.clientWidth as number) +
      Number(window.getComputedStyle(tab).marginRight.replace(/[^0-9]/g, ''))

    const scrollLeft = Math.max(
      0,
      tab.offsetLeft - (wrap.offsetWidth - tabWidth) / 2
    )

    if (!wrap.scrollTo) {
      // IE11 polyfill
      wrap.scrollLeft = scrollLeft
      return
    }

    wrap.scrollTo({
      top: 0,
      left: Math.max(0, scrollLeft),
      behavior: 'smooth',
    })
  }, [activeTabScroll, currentTabId, tabSpace])

  const renderTabItem = (item: ContentTabItem, index: number) => {
    if (item.href) {
      return (
        <Link
          href={item.href}
          passHref
          key={`TabItem${index}`}
          scroll={false}
          legacyBehavior
        >
          <Styled.Button
            as='a'
            key={`TabItem${index}`}
            tabSpace={tabSpace}
            onClick={() => handleClick(item.id)}
            ref={(tab) => {
              currentTabRefs.current[index] = tab
            }}
            aria-current={(currentTabId ?? 0) === item.id ? 'page' : undefined}
          >
            <Styled.ButtonInner isCurrent={(currentTabId ?? 0) === item.id}>
              <Styled.Dot />
              <Styled.ButtonText>{item.label}</Styled.ButtonText>
            </Styled.ButtonInner>
          </Styled.Button>
        </Link>
      )
    }

    return (
      <Styled.Button
        key={`TabItem${index}`}
        tabSpace={tabSpace}
        onClick={() => handleClick(item.id)}
        ref={(tab) => {
          currentTabRefs.current[index] = tab
        }}
        aria-current={(currentTabId ?? 0) === item.id ? 'page' : undefined}
      >
        <Styled.ButtonInner isCurrent={(currentTabId ?? 0) === item.id}>
          <Styled.Dot />
          <Styled.ButtonText>{item.label}</Styled.ButtonText>
        </Styled.ButtonInner>
      </Styled.Button>
    )
  }

  return (
    <ThemeProvider theme={Theme[tabTheme]}>
      <Styled.Container className={className}>
        <Styled.TabWrap padding={padding} ref={tabWrapRef}>
          <Styled.Tab>
            {hiddenTitle?.text && (
              <Hidden as={hiddenTitle?.as}>{hiddenTitle?.text}</Hidden>
            )}
            {list
              .sort((tab, compareTab) => tab.order - compareTab.order)
              .map(renderTabItem)}
          </Styled.Tab>
        </Styled.TabWrap>
        {line && <Styled.Line lineSpace={lineSpace} />}

        {children}
      </Styled.Container>
    </ThemeProvider>
  )
}

const Styled = {
  Container: styled.div``,
  TabWrap: styled.div<TabStyleProps>`
    position: relative;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-scrolling: auto;
  `,
  Tab: styled.nav`
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      border-top: 1px solid ${STYLE_COLOR.BLACK02};
      min-width: 100%;
      display: inline-block;
      padding: 8px 0 20px;
    }
  `,
  Button: styled.a<TabStyleProps>`
    position: relative;
    display: inline-block;
    margin: 0 0 0 ${({ tabSpace }) => tabSpace}px;
    padding: 12px 0 0 0;
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
    @media (min-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH_UPPER}) {
      &:first-of-type {
        margin-left: 0;
      }
    }
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      &:first-of-type {
        margin-left: 40px;
      }

      &:last-of-type {
        margin-right: 40px;
      }
    }
  `,
  ButtonInner: styled.div<TabStyleProps>`
    position: relative;
    &:hover {
      color: ${({ theme }) => theme.currentColor};
      ${transition}
    }

    ${({ isCurrent, theme }) => {
      return (
        isCurrent &&
        css`
          span {
            color: ${theme.currentColor};
          }
          ${transition}
        `
      )
    }}
  `,
  Dot: styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-left: -4px;
    border-radius: 50%;
    background-color: ${STYLE_COLOR.YELLOW};
    opacity: 0;
  `,
  ButtonText: styled.span<TabStyleProps>`
    ${FONT_22_EXTRA_BOLD};
    color: ${({ theme }) => theme.color};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      ${FONT_18_EXTRA_BOLD}
    }
  `,
  Line: styled.span<TabStyleProps>`
    display: block;
    margin: ${({ lineSpace }) => lineSpace}px 0 0;
    padding: 0;
    border: 0 none;
    border-top: 1px solid ${STYLE_COLOR.BLACK02};
    @media (max-width: ${STYLE_BREAKPOINT.MOBILE_MAX_WIDTH}) {
      display: none;
    }
  `,
}

export default Tabs
