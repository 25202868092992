import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ComponentStyledOptionalProps } from '@/types/Component'
import { STYLE_COLOR } from '@/styles/variables'
import { EffectType } from '@/enums/Content'
import useScrollEffect from '@/hooks/useScrollEffect'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface TransitionHandlerStyleProps extends ComponentStyledOptionalProps {
  show?: boolean
  order?: number
  speed?: number
  delay?: number
  effect: EffectType
}

interface TransitionHandlerProps extends ComponentStyledOptionalProps {
  effect?: EffectType
  infinity?: boolean
  scrollWith?: boolean
  order?: number
  speed?: number
  onShow?: (value?: boolean) => void
}

function TransitionHandler({
  children,
  className,
  effect = EffectType.FADE_UP,
  infinity = false,
  speed = 1.2,
  order = 0,
  onShow = () => undefined,
}: PropsWithChildren<TransitionHandlerProps>) {
  const [show, setShow] = useState(false)
  const div = useRef<HTMLDivElement | null>(null)

  const listener = useCallback(() => {
    if (show && !infinity) {
      return undefined
    }
    const divOffsetY =
      div.current?.getBoundingClientRect().top + window.pageYOffset
    const pageBottomOffsetY = window.pageYOffset + window.screen.availHeight

    setShow(divOffsetY <= pageBottomOffsetY)
  }, [div, infinity, show])

  useScrollEffect(listener)

  const onTransitionEnd = useCallback(() => {
    return onShow(show)
  }, [show, onShow])

  const delay = useMemo(() => {
    const delayValue = speed * order * 0.5
    return isNaN(delayValue) ? 0 : delayValue
  }, [speed, order])

  const TransitionHandlerStyleProps = {
    effect,
    show,
    speed,
    delay,
  }

  return (
    <Styled.Effect
      className={className}
      {...TransitionHandlerStyleProps}
      onTransitionEnd={onTransitionEnd}
      ref={div}
    >
      {children}
    </Styled.Effect>
  )
}

const Styled = {
  Effect: styled.div<TransitionHandlerStyleProps>`
    position: relative;
    overflow: hidden;
    ${({ speed, delay }) =>
      css`
        transition: transform ${speed}s ease-in-out ${delay}s,
          opacity ${speed}s ease-in-out ${delay}s;
      `};
    ${({ effect, show }) => {
      switch (effect) {
        case EffectType.FADE_UP:
          return css`
            overflow: visible;
            transform: translateY(${show ? '0%' : '12%'});
            opacity: ${show ? 1 : 0};
          `
        case EffectType.FADE_RIGHT:
          return css`
            overflow: visible;
            transform: translateX(${show ? '0%' : '-12%'});
            opacity: ${show ? 1 : 0};
          `
        case EffectType.FADE_SHORT_LEFT:
          return css`
            overflow: visible;
            transform: translateX(${show ? '0' : '10px'});
            opacity: ${show ? 1 : 0};
          `
        case EffectType.FADE_SHORT_RIGHT:
          return css`
            overflow: visible;
            transform: translateX(${show ? '0' : '-10px'});
            opacity: ${show ? 1 : 0};
          `
        case EffectType.ZOOM:
          return css`
            overflow: visible;
            transform: scale(${show ? 1 : 0.5});
            opacity: ${show ? 1 : 0};
          `
        case EffectType.FADE_IN:
          return css`
            overflow: visible;
            opacity: ${show ? 1 : 0};
          `
      }
    }};
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      ${({ speed, delay }) =>
        css`
          transition: transform ${speed}s ease-in-out ${delay}s,
            opacity ${speed}s ease-in-out ${delay}s;
        `};
      background-color: ${STYLE_COLOR.WHITE01};
      ${({ effect, show }) => {
        switch (effect) {
          case EffectType.WIPE_RIGHT:
            return css`
              transform: translateX(${show ? '100%' : '0%'});
            `
          case EffectType.FADE_UP:
          case EffectType.FADE_RIGHT:
          case EffectType.FADE_SHORT_LEFT:
          case EffectType.FADE_SHORT_RIGHT:
          case EffectType.FADE_IN:
          case EffectType.ZOOM:
            return css`
              content: none;
            `
        }
      }};
    }
  `,
}

export default TransitionHandler
