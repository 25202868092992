import {
  ContentBackgroundItem,
  ContentButtonItem,
  ContentHashMapItem,
  ContentImageItem,
  ContentItem,
  ContentTabItem,
  ContentTextItem,
  ContentVideoItem,
  ContentIframeItem,
  ContentListItem,
  ContentTableItem,
  ContentDescriptionItem,
  ContentHeadingItem,
  ContentCategoryItem,
  ContentLinkItem,
  ContentMetaItem,
  ContentComponentItem,
} from '@/types/Content'
import { ContentItemType } from '@/enums/Content'

interface GetElementItemHelperBase {
  image: ContentImageItem[]
  backgroundImage: ContentBackgroundItem[]
  title: ContentTextItem[]
  heading: ContentHeadingItem[]
  subTitle: ContentTextItem[]
  text: ContentTextItem[]
  button: ContentButtonItem[]
  video: ContentVideoItem[]
  hashMap: ContentHashMapItem[]

  tab: ContentTabItem[]
  tabImage: ContentImageItem[]
  tabTitle: ContentTextItem[]
  tabSubTitle: ContentTextItem[]
  tabText: ContentTextItem[]
  tabButton: ContentButtonItem[]
  iframe: ContentIframeItem[]
  list: ContentListItem[]
  table: ContentTableItem[]
  descriptions: ContentDescriptionItem[]
  category: ContentCategoryItem[]
  link: ContentLinkItem[]
  meta: ContentMetaItem[]
  component: ContentComponentItem[]
}

function elementItemHelper(items: ContentItem[]) {
  return (
    items
      ?.map((item) => {
        // 이미지의 경우 alt 속성 누락시 빈 문자열 설정
        if (item.type === ContentItemType.IMAGE) {
          return { ...item, alt: item.alt || '' }
        }
        return item
      })
      .reduce<Partial<GetElementItemHelperBase>>(function (
        accumulator,
        current
      ) {
        switch (current.type) {
          case ContentItemType.VIDEO:
            if (accumulator?.video) {
              accumulator.video.push(current)
              return accumulator
            }
            accumulator.video = [current]
            return accumulator
          case ContentItemType.IMAGE:
            if (current.tabId !== undefined) {
              if (accumulator?.tabImage) {
                accumulator.tabImage.push(current)
                return accumulator
              }
              accumulator.tabImage = [current]
              return accumulator
            }
            if (accumulator?.image) {
              accumulator.image.push(current)
              return accumulator
            }
            accumulator.image = [current]
            return accumulator
          case ContentItemType.BACKGROUND_IMAGE:
            if (Array.isArray(accumulator?.backgroundImage)) {
              accumulator.backgroundImage.push(current)
              return accumulator
            }
            accumulator.backgroundImage = [current]
            return accumulator

          case ContentItemType.TITLE:
            if (current.tabId !== undefined) {
              if (accumulator?.tabTitle) {
                accumulator.tabTitle.push(current)
                return accumulator
              }
              accumulator.tabTitle = [current]
              return accumulator
            }
            if (accumulator?.title) {
              accumulator.title.push(current)
              return accumulator
            }
            accumulator.title = [current]
            return accumulator

          case ContentItemType.TITLE_HEADING:
            if (accumulator?.heading) {
              accumulator.heading.push(current)
              return accumulator
            }
            accumulator.heading = [current]
            return accumulator

          case ContentItemType.SUB_TITLE:
            if (current.tabId !== undefined) {
              if (accumulator?.tabSubTitle) {
                accumulator.tabSubTitle.push(current)
                return accumulator
              }
              accumulator.tabSubTitle = [current]
              return accumulator
            }
            if (accumulator?.subTitle) {
              accumulator.subTitle.push(current)
              return accumulator
            }
            accumulator.subTitle = [current]
            return accumulator
          case ContentItemType.TEXT:
            if (current.tabId !== undefined) {
              if (accumulator?.tabText) {
                accumulator.tabText.push(current)
                return accumulator
              }
              accumulator.tabText = [current]
              return accumulator
            }
            if (accumulator?.text) {
              accumulator.text.push(current)
              return accumulator
            }
            accumulator.text = [current]
            return accumulator
          case ContentItemType.BUTTON:
            if (current.tabId !== undefined) {
              if (accumulator?.tabButton) {
                accumulator.tabButton.push(current)
                return accumulator
              }
              accumulator.tabButton = [current]
              return accumulator
            }
            if (accumulator?.button) {
              accumulator.button.push(current)
              return accumulator
            }
            accumulator.button = [current]
            return accumulator
          case ContentItemType.TAB:
            if (accumulator?.tab) {
              accumulator.tab.push(current)
              return accumulator
            }
            accumulator.tab = [current]
            return accumulator

          case ContentItemType.HASHMAP:
            if (accumulator?.hashMap) {
              accumulator.hashMap.push(current)
              return accumulator
            }
            accumulator.hashMap = [current]
            return accumulator

          case ContentItemType.IFRAME:
            if (accumulator?.iframe) {
              accumulator.iframe.push(current)
              return accumulator
            }
            accumulator.iframe = [current]
            return accumulator

          case ContentItemType.LIST:
            if (accumulator?.list) {
              accumulator.list.push(current)
              return accumulator
            }
            accumulator.list = [current]
            return accumulator
          case ContentItemType.TABLE_BORDERED:
            if (accumulator?.table) {
              accumulator.table.push(current)
              return accumulator
            }
            accumulator.table = [current]
            return accumulator

          case ContentItemType.DESCRIPTIONS:
            if (accumulator?.descriptions) {
              accumulator.descriptions.push(current)
              return accumulator
            }
            accumulator.descriptions = [current]
            return accumulator
          case ContentItemType.CATEGORY:
            if (accumulator?.category) {
              accumulator.category.push(current)
              return accumulator
            }
            accumulator.category = [current]
            return accumulator
          case ContentItemType.LINK:
            if (accumulator?.link) {
              accumulator.link.push(current)
              return accumulator
            }
            accumulator.link = [current]
            return accumulator
          case ContentItemType.META:
            if (accumulator?.meta) {
              accumulator.meta.push(current)
              return accumulator
            }
            accumulator.meta = [current]
            return accumulator
          case ContentItemType.COMPONENT:
            if (accumulator?.meta) {
              accumulator.component.push(current)
              return accumulator
            }
            accumulator.component = [current]
            return accumulator
        }
        return accumulator
      },
      {}) ?? {}
  )
}

export default elementItemHelper
