import { AxiosError } from 'axios'
import useSWRInfinite from 'swr/infinite'
import { callNoticeDataApi } from '@/apis/noticeData'
import { NoticeList, NoticeType } from '@/types/Notice'
import { ApiPath } from '@/constants/Path'
import { HttpMethod } from '@/helpers/createApiCall'

export default function useGetNotices({
  noticeTypes,
  size,
  initialSize,
}: {
  noticeTypes: NoticeType[]
  size: number
  initialSize?: number
}) {
  const {
    data,
    setSize: setPage,
    error,
    isLoading,
  } = useSWRInfinite<NoticeList, AxiosError>(
    (pageIndex) => {
      const fetchSize = pageIndex === 0 && initialSize ? initialSize : size

      return ApiPath.GET_NOTICES({
        notice_types: noticeTypes,
        size: fetchSize,
        offset: (() => {
          if (pageIndex === 0) {
            return 0
          }
          return (initialSize || size) + size * (pageIndex - 1)
        })(),
      })
    },
    async (url: string) => {
      return (await callNoticeDataApi({ method: HttpMethod.GET, path: url }))
        .data as NoticeList
    }
  )

  const fetchNextPage = () => {
    void setPage((page) => page + 1)
  }

  return { data, error, isLoading, fetchNextPage }
}
